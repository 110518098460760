import React from 'react';

import onlineMarketingImage1 from 'assets/images/pages/diensten_onlinemarketing/1.jpg';
import onlineMarketingImage2 from 'assets/images/pages/diensten_onlinemarketing/2.jpg';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';

const DienstenMarketing = () => (
  <Layout>
    <Seo image={{ src: onlineMarketingImage1 }} />

    <Title
      image={{ src: onlineMarketingImage1 }}
      alt="Online marketing voor Magento webshops"
      subtitle="Gerichte online marketing ondersteuning"
      title="Online marketing voor Magento webshops"
    >
      Of het nu gaat om SEO, SEA of social media: wij kunnen je adviseren,
      opleiden of je het werk uit handen nemen
    </Title>

    <Section>
      <Image
        image={{ src: onlineMarketingImage2 }}
        alt="Jouw webshop prestaties houden wij goed in de gaten"
      >
        <Heading size="xl" as="h2">
          Jouw webshop prestaties houden wij goed in de gaten
        </Heading>

        <Text spacing="lg">
          Wist je dat we ons team inmiddels hebben uitgebreid met bewezen
          succesvolle online marketeers? Hierdoor kunnen we je een nog
          completere dienstverlening aanbieden, waardoor we nu naast de
          conversiegerichte en technisch en visueel hoogstaande Magento
          webshops, ook de beste online marketing hulp of consultancy kunnen
          leveren.
        </Text>

        <Button color="tertiary" to="/contact">
          Bespreek met ons de mogelijkheden
        </Button>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenMarketing;
